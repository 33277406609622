import firebase from "firebase/app";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyCY9lYV07cVcWuin1yaUo4JCItzpdOi2JM",
  authDomain: "cedar-mingle.firebaseapp.com",
  databaseURL: "https://cedar-mingle.firebaseio.com",
  projectId: "cedar-mingle",
  storageBucket: "cedar-mingle.appspot.com",
  messagingSenderId: "454983196248",
  appId: "1:454983196248:web:591ab2598b6290c193742d",
  measurementId: "G-W53C7MN504",
};

const firebaseApp = firebase.initializeApp(config);
const analytics = firebase.analytics();

export { firebaseApp, analytics };
